import { render, staticRenderFns } from "./ContactCard.vue?vue&type=template&id=7b20724e&"
import script from "./ContactCard.vue?vue&type=script&lang=js&"
export * from "./ContactCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.hva/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BButton, BCard} from 'bootstrap-vue'
    installComponents(component, {BButton, BCard})
    

export default component.exports